import React from "react";
import SimpleLanding from "../../../layouts/simple-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";

const HELOCDisclosures = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "HELOC Disclosures"
    }
  ];

  const SEOData = {
    title: "HELOC Disclosures",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Bank Home Equity Disclosure Important Terms of Our Home Equity Line of Credit</h1>
        <p>
          (Disclosures apply to subject property located in Washington, Oregon, Idaho, Utah, Nevada, Arizona or New
          Mexico)
        </p>
        <p>
          <strong>NMLSR Company Identifier 410394</strong>
        </p>
        <p>
          This disclosure contains important information about our Home Equity Line of Credit (the &ldquo;Account&rdquo;
          or &ldquo;Line of Credit&rdquo;). You should read it carefully and keep a copy for your records. In this
          disclosure, the words &ldquo;you&rdquo; and &ldquo;your&rdquo; mean each person applying for the Line of
          Credit and anyone else who will be authorized to use it. The words &ldquo;we,&rdquo; &ldquo;us,&rdquo; and
          &ldquo;our&rdquo; mean WaFd Bank.
        </p>
        <p>
          <strong>Availability of Terms:</strong> All of the terms described below are subject to change. If the terms
          change (other than the ANNUAL PERCENTAGE RATE due to a change in the interest rate index) and you decide, as a
          result, not to enter into an agreement with us, you are entitled to a refund of any fees that you have paid to
          us or anyone else in connection with your Line of Credit application.
        </p>
        <p>
          <strong>Security Interest:</strong> We will take a security interest in your home. You could lose your home if
          you do not meet the obligations in your agreement with us.
        </p>
        <p>
          <strong>Possible Actions:</strong> We may terminate your Line of Credit, require you to pay us the entire
          outstanding balance in one payment, and charge you certain fees and other costs if:
        </p>
        <ol>
          <li>
            You engage in fraud or material misrepresentation in connection with the Line of Credit. For example, you
            make a false statement about your income or any other aspect of your financial condition
          </li>
          <li>You do not meet the repayment terms of the Line of Credit agreement.</li>
          <li>
            Your action or inaction adversely affects the collateral (your home) or our rights in the collateral. For
            example, you do not maintain adequate insurance or you fail to pay taxes.
          </li>
        </ol>
        <p>We may refuse to make additional extensions of credit or reduce your credit limit, or both, if:</p>
        <ol>
          <li>
            The value of your home securing the Line of Credit declines significantly below its appraised value for
            purposes of the Line of Credit.
          </li>
          <li>
            We reasonably believe you will not be able to meet the repayment requirements due to a material change in
            your financial circumstances or declining credit scores reflected when we re-examine your credit report.
          </li>
          <li>You are in default of a material obligation in the Line of Credit agreement.</li>
          <li>
            Government action prevents us from imposing the annual percentage rate provided for in the Line of Credit
            agreement or impairs the priority of our security interest such that the value of the security interest is
            less than 120 percent of the credit line.
          </li>
          <li>
            A regulatory agency has notified us that continued advances would constitute an unsafe and unsound business
            practice.
          </li>
          <li>
            The maximum annual percentage rate we are permitted to charge under the Line of Credit agreement is reached.
          </li>
        </ol>
        <p>
          <strong>Minimum Payment Requirements:</strong> You may obtain advances of credit for 10 years (the &ldquo;Draw
          Period&rdquo;). During the Draw Period, payments will be due monthly. Your minimum monthly payment will be
          equal to the total periodic finance charge for the monthly billing cycle, plus any unpaid fees and charges and
          past due amounts. The minimum monthly payments during the Draw Period will not reduce the principal that is
          outstanding on your Line of Credit. You may pay the entire balance at any time without penalty, however, you
          must pay at least the total amount due shown on your statement each month.
        </p>
        <p>
          After the Draw Period ends, you will no longer be able to obtain credit advances and must pay the outstanding
          balance over 15 years (the "Repayment Period"). During the Repayment Period, payments will be due monthly.
          Your minimum monthly payment will equal the amount of principal plus periodic FINANCE CHARGE necessary to
          repay the outstanding balance over 15 years in substantially equal, fully amortizing monthly payments.
        </p>
        <p>
          <strong>Minimum Payment Example:</strong> If you made only the minimum monthly payments and took no other
          credit advances, it would take 25 years to pay off a credit advance of $10,000 at an ANNUAL PERCENTAGE RATE of
          8.25%. During the Draw Period you would make 120 monthly payments of $68.75 (interest only) followed by 180
          monthly payments during the Repayment Period of $97.02 (principal and interest). The monthly payments will
          vary due to adjustments in the interest rate.
        </p>
        <p>
          <strong>Initial Credit Advance:</strong> "Initial Credit Advance" means the amount of money we will require
          you to accept as an advance to open the plan. A minimum initial credit advance is not required.
        </p>
        <h2>HELOC Special</h2>
        <p>
          WaFd Bank offers a promotional introductory rate on our Home Equity Lines of Credit (HELOC) to borrowers who
          meet certain eligibility criteria. The following promotional terms are offered to borrowers who meet the
          Eligibility Requirements:
        </p>
        <ul id="heloc-special-eligibility-requirements-1-list">
          <li id="heloc-special-eligibility-requirements-1-list-item-1">
            An introductory rate discount of 1% (one percent) applied to the annual percentage rate. In determining the
            annual percentage rate, we use the current index value of the <strong>Prime Rate*</strong> and add a margin.
          </li>
          <li id="heloc-special-eligibility-requirements-1-list-item-2">
            The promotional Introductory Rate is an initial rate discount that is fixed for the first six (6) billing
            cycles following the day your Home Equity Line of Credit is opened.
          </li>
        </ul>
        <p>
          <strong>Eligibility Requirements**</strong>: To be eligible for the promotional introductory rate, borrowers
          must meet the following criteria:
        </p>
        <ul id="heloc-special-eligibility-requirements-2-list">
          <li id="heloc-special-eligibility-requirements-2-list-item-1">
            Set-up on WaFd's EZ Pay payment option for the Home Equity Line of Credit
          </li>
          <li id="heloc-special-eligibility-requirements-2-list-item-2">
            Open or maintain a WaFd Bank personal checking account with a recurring Direct Deposit
          </li>
        </ul>
        <p>
          For borrowers who meet the eligibility requirements for the promotional introductory rate, the annual
          percentage rate, index and margin can be found on the Home Equity Line of Credit Agreement provided at
          closing. If you do not meet the eligibility requirements for a promotional introductory rate, a non-discounted
          rate will apply. Speak with your loan officer to confirm eligibility for this special introductory rate.
        </p>
        <p>
          *The <strong>Prime Rate</strong> is published in the "Money Rates" table in The Wall Street Journal; if more
          than one index value is published we will use the highest published index value.
        </p>
        <p>**The Exit HELOC program is not eligible for the promotional introductory rate.</p>
        <h2>Fees and Charges</h2>
        <p>
          <strong>Origination Fee</strong>
        </p>
        <p>
          We may require you to pay an Origination Fee to open your Account. For Lines of Credit over $250,000 that are
          secured by owner-occupied primary and secondary residences, we will charge an Origination Fee of $700. If the
          Line of Credit will be secured by an investment property (not owner-occupied), we will charge an Origination
          Fee that is the greater of 1% of the Line of Credit amount or $700.
        </p>
        <p>
          <strong>Third Party Fees</strong>
        </p>
        <p>
          To open an Account, we may require you to pay certain fees to third parties. The fees and the conditions under
          which we may waive the fees are described below.
        </p>
        <p>
          For Lines of Credit up to $250,000 that are secured by owner-occupied primary and secondary residences, we
          will pay certain third party fees (including title insurance, appraisal or property valuation, credit report,
          flood certification and outside escrow fee) if:
        </p>
        <ul>
          <li>
            You currently have a mortgage with us that is the first lien on the residence and the Line of Credit will be
            secured by a second lien on the residence.
          </li>
          <li>
            The Line of Credit will be secured by a first lien on the residence that will be the only lien at the time
            of the Line of Credit is opened (either because there is no existing lien or the prior liens are paid off).
          </li>
        </ul>
        <p>
          We may collect the credit report fee and the appraisal fee from you prior to closing and if you qualify to
          have us pay your fees, these fees will be credited back to you at the time of closing of the Line of Credit.
          We will not waive the fees for excise or transfer taxes, multiple appraisals or appraisal rush fee, flood
          insurance, condo questionnaire, well or septic inspection fees, final inspection/442 fee, survey fees, or
          other inspections or services required to underwrite your Line of Credit.
        </p>
        <p>
          If your Line of Credit does not meet the above criteria, you must also pay certain fees to third parties.
          These include fees for credit reports, flood certification, appraisers, notary service, wire, county or state
          recorder or title companies. These fees generally vary by state and loan amount. An example of the ranges are
          provided below:
        </p>
        <p>
          <strong>Arizona:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$865 - $4,214</td>
              <td>$865 - $4,214</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$2,745 - $6,450</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Idaho:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$585- $3,849</td>
              <td>$585- $3,849</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$2,135 - $7,579</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Oregon:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$1,022 - 3,744</td>
              <td>$1,022 - 3,744</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$3,295 - $5,794</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Nevada:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$795 - $3,489</td>
              <td>$795 - $3,489</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$1,880 - $5,589</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>New Mexico:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$1,575- $4,519</td>
              <td>$1,575- $4,519</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$2,815 - $7,399</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Utah and Washington:</strong>
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <th>Loan Amount Range</th>
              <th>Range of fee</th>
              <th>
                Paid By Lender<sup>*</sup>
              </th>
            </tr>
            <tr>
              <td>Up to $250,000</td>
              <td>$545 - $3,235</td>
              <td>$545 - $3,235</td>
            </tr>
            <tr>
              <td>$250,001 - $700,000</td>
              <td>$1,926- $4,894</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
        <p className="text-muted">
          <sup>*</sup>For Lines of Credit where the lien will be the only lien on the residence or the lien will be a
          second lien and the first lien is also held by WaFd Bank.
        </p>
        <p>If you ask, we will provide you with an itemization of fees you will have to pay to third parties.</p>
        <p>
          <strong>Annual Fee:</strong> We do not charge an annual fee.
        </p>
        <p>
          <strong>Cancellation Fee:</strong> We will charge a cancellation fee of $750.00 if you close your Account
          within the first 3 years after opening the Account.
        </p>
        <p>
          <strong>Check Fees:</strong> We will provide you with the first set of checks on the Account at no charge. If
          you need additional checks they will be provided at our then current rate for checks.
        </p>
        <p>
          <strong>Tax Deductibility:</strong> You should consult a tax advisor regarding the deductibility of interest
          and charges for the Line of Credit.
        </p>
        <p>
          <strong>Variable Rate Information:</strong> The Line of Credit has a variable-rate feature, and the ANNUAL
          PERCENTAGE RATE (corresponding to the periodic rate) and the minimum payment can change as a result.
        </p>
        <p>The annual percentage rate includes only interest and no other costs.</p>
        <p>
          <strong>The Index:</strong> The annual percentage rate is based on the value of an index
          (&ldquo;Index&rdquo;). The Index is the Wall Street Journal Prime Rate published in the "Money Rates" section
          of the Wall Street Journal on the day prior to the adjustment. When the range of rates has been published, the
          highest of the rates will be used. The Index shall be adjusted monthly on the first day of the month. To
          determine the annual percentage rate that will apply to your Line of Credit, we may add a margin to the value
          of the Index. If the Wall Street Journal Prime Rate is no longer available, we will choose a new Index and
          margin.
        </p>
        <p>
          Ask us for the current Index value, margin and annual percentage rate. After you open the Account, rate
          information will be provided on periodic statements that we will send to you.
        </p>
        <p>
          <strong>Rate Changes:</strong> The annual percentage rate can change monthly during the Draw Period and
          monthly during the Repayment Period. The maximum ANNUAL PERCENTAGE RATE that can apply is 17.99%. The minimum
          ANNUAL PERCENTAGE RATE that can apply is 3.00%. Ext for the 17.99% &ldquo;cap" and 3.00% minimum rate there is
          no limit on the amount by which the rate can change during any one-year period
        </p>
        <p>
          <strong>Maximum Rate and Payment Examples:</strong> If you had an outstanding balance of $10,000.00 during the
          Draw Period, the minimum monthly payment at the maximum ANNUAL PERCENTAGE RATE of 17.99% would be $149.92.
          This annual percentage rate could be reached during the first month of the Draw Period.
        </p>
        <p>
          If you had an outstanding balance of $10,000.00 at the beginning of the Repayment Period, the minimum monthly
          payment at the maximum ANNUAL PERCENTAGE RATE OF 17.99% would be $160.98. This annual percentage rate could be
          reached during the first month of the Repayment Period.
        </p>
        <p>
          <strong>Historical Example:</strong> The following table shows how the annual percentage rate and the minimum
          monthly payments for a single $10,000.00 credit advance would have changed based on changes in the Index over
          the past 15 years. The Index values are from September of each year. While only one payment amount is shown
          per year, payments may vary during each year. The table below assumes that no additional credit advances were
          taken, that only the minimum payments were made each month, and that the rate remained constant during each
          year. It does not necessarily indicate how the Index or your payment will change.
        </p>
        <div className="table-responsive">
          <table className="table table-bordered my-3">
            <tbody>
              <tr>
                <th>Year</th>
                <th>Index (%)</th>
                <th>Margin (%)</th>
                <th>ANNUAL PERCENTAGE RATE (%)</th>
                <th>Minimum Monthly Payment ($)</th>
              </tr>
              <tr>
                <td colspan="5">Draw Period</td>
              </tr>
              <tr>
                <td>2004</td>
                <td>4.750</td>
                <td>0.000</td>
                <td>4.750</td>
                <td>39.58</td>
              </tr>
              <tr>
                <td>2005</td>
                <td>6.750</td>
                <td>0.000</td>
                <td>6.750</td>
                <td>56.25</td>
              </tr>
              <tr>
                <td>2006</td>
                <td>8.250</td>
                <td>0.000</td>
                <td>8.250</td>
                <td>68.75</td>
              </tr>
              <tr>
                <td>2007</td>
                <td>7.750</td>
                <td>0.000</td>
                <td>7.750</td>
                <td>64.58</td>
              </tr>
              <tr>
                <td>2008</td>
                <td>5.000</td>
                <td>0.000</td>
                <td>5.000</td>
                <td>41.67</td>
              </tr>
              <tr>
                <td>2009</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td>2010</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td colspan="5">Repayment Period</td>
              </tr>
              <tr>
                <td>2011</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2012</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2013</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2014</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2015</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>71.49</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>4.250</td>
                <td>0.000</td>
                <td>4.250</td>
                <td>75.23</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>5.000</td>
                <td>0.000</td>
                <td>5.000</td>
                <td>79.08</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          If you choose the EZ Pay automatic payment method to have your regular Minimum Monthly Payments automatically
          deducted from deposit account, the ANNUAL PERCENTAGE RATE includes a discount given to you for automatic
          payments. If the deposit account you authorize for E-Z pay automatic payment is a WaFd Bank Green or Stellar
          checking account, the ANNUAL PERCENTAGE RATE includes an additional discount given for these WaFd Bank
          accounts. If the automatic payments are terminated for any reason by anyone, the ANNUAL PERCENTAGE RATE on the
          Account will increase by 1.00 percentage point effective the first day of the month following the termination.
          In addition to any applicable rate increase for termination of E-Z Pay automatic payments, if E-Z Pay
          automatic payments are terminated from a WaFd Bank Green or Stellar checking account, the ANNUAL PERCENTAGE
          RATE on the Account will increase by a .25 percentage point effective the first day of the month following the
          termination.
        </p>
        <p>
          If you do not choose to use the E-Z Pay automatic payments, the Automatic Payments Discount is not applicable
          to the Account.
        </p>
        <p>
          <strong>
            Appraisals and Property Valuation Reports for second lien transactions: You have the right to a copy of the
            appraisal or property valuation report used in connection with your Line of Credit application for credit if
            one was obtained. If you wish to receive a copy, please write to us at WaFd Bank, Attn: Loan Operations, 425
            Pike Street, Seattle, WA 98101. We must hear from you no later than 90 days after we notify you about the
            actions taken on your Line of Credit application or you withdraw your application.
          </strong>
        </p>
      </section>
      <section className="container">
        <h2 className="text-center">
          WaFd Bank Home Equity Disclosure Important Terms of Our Home Equity Line of Credit
        </h2>
        <p className="text-center">
          (Disclosures apply to subject property located in Texas) Creditor: WaFd Bank NMLSR 410394
        </p>

        <p>
          This disclosure contains important information about our Home Equity Line of Credit ("Line"). You should read
          it carefully and keep a copy for your records. In this disclosure, the words &ldquo;you" and "your&rdquo; mean
          each person applying for the Line and anyone else who will be authorized to use it. The words "we," "us," and
          "our" mean WaFd Bank.
        </p>
        <p>
          <strong>Availability of Terms:</strong> To obtain the terms described below, you must submit your Line
          application within 90 days of receiving this notice. All of the terms described below are subject to change.
          If these terms change (other than the ANNUAL PERCENTAGE RATE) and you decide, as a result, not to enter into
          an agreement with us, you are entitled to a refund of any fees that you paid to us or anyone else in
          connection with your application.
        </p>
        <p>
          <strong>Security Interest:</strong> We will take a security interest in your home. You could lose your home if
          you do not meet the obligations in your agreement with us.
        </p>
        <p>
          <strong>Possible Actions:</strong>
        </p>
        <p>
          We can terminate your Line, require you to pay us the entire outstanding balance in one payment, and charge
          you certain fees if:
        </p>
        <ol>
          <li>you engage in fraud or material misrepresentation in connection with the Line;</li>
          <li>you do not meet the repayment terms; or</li>
          <li>your action or inaction adversely affects the collateral or our rights in the collateral.</li>
        </ol>
        <p>We can refuse to make additional extensions of credit or reduce your credit limit, or both if:</p>
        <ol>
          <li>
            the value of the dwelling securing the Line declines significantly below its appraised value for purposes of
            the Line;
          </li>
          <li>
            {" "}
            we reasonably believe you will not be able to meet the repayment requirements due to a material change in
            your financial circumstances;
          </li>
          <li>you are in default of a material obligation in the agreement;</li>
          <li>
            government action prevents us from imposing the ANNUAL PERCENTAGE RATE provided for or impairs our security
            interest such that the value of the interest is less than 120 percent of the Line;
          </li>
          <li>
            a regulatory agency has notified us that continued advances would constitute an unsafe and unsound business
            practice; or
          </li>
          <li>
            the maximum ANNUAL PERCENTAGE RATE is reached. The initial agreement permits us to make certain changes to
            the terms of the agreement at specified times or upon the occurrence of specified events.
          </li>
        </ol>
        <p>
          <strong>Minimum Payment Requirements:</strong> You can obtain advances of credit for 10 years (the "draw
          period"). Payments during the draw period will be due monthly. The minimum monthly payment during the draw
          period will be the amount of accrued FINANCE CHARGES plus any unpaid fees and charges and unpaid amounts. The
          minimum monthly payments during the draw period will not reduce the principal that is outstanding on your
          Line. You may pay the entire balance at any time without penalty, however, you must pay at least the total
          amount shown on your statement each month. At the end of the draw period, you no longer will be able to obtain
          advances and must pay the outstanding balance on your Line during the following 15 years (the "repayment
          period"). Payments during the repayment period will be due monthly. The minimum monthly payment during the
          repayment period will be the amount sufficient to repay the principal balance of the Line at the end of the
          draw period plus accrued FINANCE CHARGES in substantially equal payments at the ANNUAL PERCENTAGE RATE then in
          effect.
        </p>
        <p>
          <strong>Minimum Payment Examples:</strong> If you made only the minimum monthly payments and took no other
          credit advances, it would take 25 years to pay off a credit advance of $10,000 at an ANNUAL PERCENTAGE RATE OF
          8.25%. During that period, you would make 120 monthly payments of $68.75, (interest only) followed by 179
          payments of $97.01(principal &amp; interest), and with a final payment of $98.53.
        </p>
        <p>
          <strong>Fees And Charges:</strong> To open and maintain a Line, if the request is less than $250,000 you must
          pay the following fees to us:
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <td colspan="2">
                <strong>(due at closing)</strong>
              </td>
            </tr>
            <tr>
              <td>Document Preparation</td>
              <td>$700.00</td>
            </tr>
            <tr>
              <td colspan="2">
                <strong>(due at application)</strong>
              </td>
            </tr>
            <tr>
              <td>Credit Report Fee</td>
              <td>$00.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Fees And Charges:</strong> To open and maintain a Line, if the request is over $250,000 you must pay
          the following fees to us:
        </p>
        <table className="table table-bordered my-3">
          <tbody>
            <tr>
              <td colspan="2">
                <strong>(due at closing)</strong>
              </td>
            </tr>
            <tr>
              <td>Tax Service Fee (range)</td>
              <td>$ 79 up to $ 109</td>
            </tr>
            <tr>
              <td>Wire Fee</td>
              <td>$ 25.00</td>
            </tr>
            <tr>
              <td>Flood Certification Fee</td>
              <td>$ 16.00</td>
            </tr>
            <tr>
              <td>Appraisal (estimate)</td>
              <td>$ 800.00</td>
            </tr>
            <tr>
              <td>Document Preparation</td>
              <td>$ 700.00</td>
            </tr>
            <tr>
              <td colspan="2">
                <strong>(due at application)</strong>
              </td>
            </tr>
            <tr>
              <td>Credit Report Fee</td>
              <td>$ 35.00</td>
            </tr>
            <tr>
              <td colspan="2">
                <strong>(due when incurred)</strong>
              </td>
            </tr>
            <tr>
              <td>Stop Payment Charge</td>
              <td>$ 25</td>
            </tr>
            <tr>
              <td>NSF Fee</td>
              <td>$ 25</td>
            </tr>
            <tr>
              <td>Annual Charge (due each year)</td>
              <td>$ 0</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Late Charge:</strong> A late charge on any monthly payment not paid within 15 calendar days from the
          date the payment is due. The amount of the charge will be U.S. $25.00; provided, however, Holder may not
          charge this late charge to the extent prohibited by applicable law.
        </p>
        <p>
          <strong>Other Loan Fees and Charges</strong> may include fees payable to third parties; you also may have to
          pay certain other fees to third parties. All third party fees generally total between $0.00 and $5,000.00. If
          you ask, we will give you an itemization of the fees you will have to pay to third parties. If you decide not
          to open a Line with us after your receipt of this disclosure and the brochure "What You Should Know About Home
          Equity Lines of Credit," you have a right to a refund of all fees, if any, that you have paid. You must carry
          insurance on the property that secures your Line.
        </p>
        <p>
          <strong>Minimum Advance Requirements:</strong> The minimum credit advance you can receive is $4,000.00.
        </p>
        <p>
          <strong>Tax Deductibility:</strong> You should consult a tax advisor regarding the deductibility of interest
          and charges for the Line.
        </p>
        <p>
          <strong>Other Products:</strong> At this time we have only one type of home equity line of credit product
          available
        </p>
        <p>
          <strong>Variable Rate Features:</strong> The Line has a variable rate feature, and the ANNUAL PERCENTAGE RATE
          (corresponding to the periodic rate) and the minimum monthly payment can change as a result. The ANNUAL
          PERCENTAGE RATE includes only interest and not other costs.
        </p>
        <p>
          <strong>The Index:</strong> The ANNUAL PERCENTAGE RATE is based on the value of an index. The index is the
          Wall Street Journal Prime Rate published in the &ldquo;Money Rates&rdquo; section of the Wall Street Journal
          on the day prior to the adjustment. When the range of rates has been published, the highest of the rates will
          be used. The Index shall be adjusted monthly on the first day of the month. To determine the annual percentage
          rate that will apply to your Line of Credit, we may add a margin to the value of the Index. If the Wall Street
          Journal Prime Rate is no longer available, we will choose a new Index and margin.
        </p>
        <p>
          <strong>Initial Rate Not Discounted.</strong> The initial ANNUAL PERCENTAGE RATE is not "discounted" and is
          based on the index and margin used for adjustments. Ask us for the current index value, margin, discount or
          premium, if applicable, and ANNUAL PERCENTAGE RATE. After you open a Line, rate information will be provided
          on periodic statements that we send you.
        </p>
        <p>
          <strong>Rate Changes:</strong> The ANNUAL PERCENTAGE RATE can change monthly. The maximum ANNUAL PERCENTAGE
          RATE that can apply during the plan is 17.99% or the highest allowable rate for this type of Agreement as
          determined by applicable state or federal law. The minimum ANNUAL PERCENTAGE RATE that can apply is 3.000%.
          Except for the maximum and minimum rates, there is no limit on the amount by which the ANNUAL PERCENTAGE RATE
          can change in any one year period.
        </p>
        <p>
          <strong>Maximum Rate And Payment Examples:</strong> If you had an outstanding balance of $10,000 at the
          beginning of the draw period, the minimum monthly payment at the maximum ANNUAL PERCENTAGE RATE of 17.99%
          would be $149.92. The maximum ANNUAL PERCENTAGE RATE during the draw period could be reached immediately.
        </p>
        <p>
          If you had an outstanding balance of $10,000 at the beginning of the repayment period, the minimum monthly
          payment at the maximum ANNUAL PERCENTAGE RATE of 17.99% would be $160.97. The maximum ANNUAL PERCENTAGE RATE
          during the repayment period could be reached the first day the repayment period begins.
        </p>
        <p>
          <strong>Historical Examples:</strong> The following table shows how the ANNUAL PERCENTAGE RATE and the minimum
          payments for a single $10,000 credit advance would have changed based on changes in the index over the last 15
          years. The index values are from the first business day of January. The table assumes that no additional
          credit advances were taken, that only the minimum payments were made, and that the ANNUAL PERCENTAGE RATE
          remained constant during each year. It does not necessarily indicate how the index or your payments would
          change in the future.
        </p>
        <div className="table-responsive">
          <table className="table table-bordered my-3">
            <tbody>
              <tr>
                <th>Year</th>
                <th>Index (%)</th>
                <th>
                  Margin (%)<sup>*</sup>
                </th>
                <th>
                  ANNUAL PERCENTAGE RATE (%)<sup>**</sup>
                </th>
                <th>Minimum Monthly Payment ($)</th>
              </tr>
              <tr>
                <td colspan="5">Draw Period</td>
              </tr>
              <tr>
                <td>2004</td>
                <td>4.000</td>
                <td>0.000</td>
                <td>4.000</td>
                <td>33.33</td>
              </tr>
              <tr>
                <td>2005</td>
                <td>5.250</td>
                <td>0.000</td>
                <td>5.250</td>
                <td>43.75</td>
              </tr>
              <tr>
                <td>2006</td>
                <td>7.250</td>
                <td>0.000</td>
                <td>7.250</td>
                <td>60.42</td>
              </tr>
              <tr>
                <td>2007</td>
                <td>8.250</td>
                <td>0.000</td>
                <td>8.250</td>
                <td>68.75</td>
              </tr>
              <tr>
                <td>2008</td>
                <td>7.250</td>
                <td>0.000</td>
                <td>7.250</td>
                <td>60.42</td>
              </tr>
              <tr>
                <td>2009</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td>2010</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td>2011</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td>2012</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td>2013</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>27.08</td>
              </tr>
              <tr>
                <td colspan="5">Repayment Period</td>
              </tr>
              <tr>
                <td>2014</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2015</td>
                <td>3.250</td>
                <td>0.000</td>
                <td>3.250</td>
                <td>70.27</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>3.500</td>
                <td>0.000</td>
                <td>3.500</td>
                <td>71.34</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>3.750</td>
                <td>0.000</td>
                <td>3.750</td>
                <td>72.35</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>4.500</td>
                <td>0.000</td>
                <td>4.500</td>
                <td>75.17</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-muted">
          <sup>*</sup>This is a margin we have used recently; your margin may be different.
        </p>
        <p className="text-muted">
          <sup>**</sup>The maximum ANNUAL PERCENTAGE RATE for purposes of this table is 17.99%; your maximum ANNUAL
          PERCENTAGE RATE may be different. The minimum ANNUAL PERCENTAGE RATE for purposes of this table is 3.000%;
          your minimum ANNUAL PERCENTAGE RATE may be different. If you choose the EZ Pay automatic payment method to
          have your regular Minimum Monthly Payments automatically deducted from a WaFd Bank deposit account, the ANNUAL
          PERCENTAGE RATE includes a discount given to you for automatic payments. If the deposit account you authorize
          for E-Z pay automatic payment is a WaFd Bank Green or Stellar checking account, the ANNUAL PERCENTAGE RATE
          includes an additional discount given for these WaFd Bank accounts. If the automatic payments are terminated
          for any reason by anyone, the ANNUAL PERCENTAGE RATE on the Account will increase by 1.00 percentage point
          effective the first day of the month following the termination. In addition to any applicable rate increase
          for termination of E-Z Pay automatic payments, if E-Z Pay automatic payments are terminated from a WaFd Bank
          Green or Stellar checking account, the ANNUAL PERCENTAGE RATE on the Account will increase by a .25 percentage
          point effective the first day of the month following the termination.
        </p>
        <p>
          If you do not choose to use the E-Z Pay automatic payments, the Automatic Payments Discount is not applicable
          to the Account.
        </p>
        <p>
          <strong>
            Appraisals and Property Valuation Reports for second lien transactions: You have the right to a copy of the
            appraisal or property valuation report used in connection with your Line of Credit application for credit.
            If you wish to receive a copy, please write to us at WaFd Bank, Attn: Loan Operations, 425 Pike Street,
            Seattle, WA 98101. We must hear from you no later than 90 days after we notify you about the actions taken
            on your Line of Credit application or you withdraw your application.
          </strong>
        </p>
      </section>
    </SimpleLanding>
  );
};

export default HELOCDisclosures;
